<template>
  <div class="bjph">
    <div class="bjph-item" v-for="item,index in dataList" :key="index">
       <!-- @click="handleCheke(item)" :id="'alarmItem' + item.id" :class="{ 'ssbj-item-c': currentVehicle && currentVehicle.id == item.id }" -->
      <div class="bjph-car">
        <!-- <img :src="require(`../../../../assets/images/OperationBigScreen/DeploymentControl/bjph-icon${index < 2 ? index : ''}.png`)" class="bjph-car-icon" alt="" srcset=""> -->
        <div class="bjph-car-icon" :style="{ backgroundImage: `url(${require('../../../../assets/images/OperationBigScreen/DeploymentControl/bjph-icon'+ (index < 2 ? index : '') +'.png')})` }">{{ index + 1 }}</div>
        <img src="https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/excavating_machinery.png" class="bjph-car-img" alt="" srcset="">
        <div class="bjph-car-centent">
          <div class="bjph-car-centent-name">{{ item.plate }}</div>
          <div class="bjph-car-centent-type">{{ item.machineryEquipmentTypeName }} {{ item.frameNo }}</div>
          <div class="bjph-car-centent-g">车组：{{ item.groupName }}</div>
        </div>
        <div class="bjph-car-num" :class="['bjph-car-num' + index]">{{ item.count }}</div>
      </div>
    </div>
    <empty v-if="!dataList || !dataList.length" style="margin-top: 30vh" text="暂无告警数据" />
  </div>
</template>

<script>
import empty from "@/views/OperationBigScreen/DeploymentControl/components/empty";
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    currentVehicle: {
      type: Object,
      default: ()=> {}
    }
  },
  components: { empty },
  watch: {
    currentVehicle: {
      handler(v) {
        if (v && v.id) {
          this.scrollToAnchor('alarmItem' + v.id)
        }
      },
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleCheke(e){
      this.$emit('changeAlarm', e)
    },
    scrollToAnchor(anchor) {
      this.$nextTick(() => {
        const element = document.getElementById(anchor);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.bjph {
  flex: 1;
  overflow: auto;
  &-item {
    width: 100%;
    // height: 98px;
    padding: 20px 12px;
    background-image: url('../../../../assets/images/OperationBigScreen/DeploymentControl/bjph-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // cursor: pointer;
  }
  &-car {
    position: relative;
    display: flex;
    align-items: center;
    &-icon {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      color: #FFE3E3;
      margin-right: 8px;
      padding-left: 4px;
      padding-bottom: 4px;
    }
    &-img {
      width: 66px;
      height: 66px;
      margin-right: 8px;
    }
    &-num {
      position: absolute;
      right: 0;
      top: 0;
      // width: 46px;
      height: 20px;
      line-height: 20px;
      font-weight: 500;
      font-size: 13px;
      color: #FFFFFF;
      padding: 0 10px;
      background: #1D487E;
      border-radius: 10px;
    }
    &-num0{
      background: #862728;
    }
    &-num1{
      background: #BD8C3E;
    }
    &-centent {
      height: 66px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-name {
        font-weight: bold;
        font-size: 18px;
        color: #B2D9FF;
      }
      &-type {
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
      &-g {
        font-size: 14px;
        color: #FFFFFF;
      }
    }
  }
}
</style>