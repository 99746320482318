var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentVehicle)?_c('div',{staticClass:"window"},[_c('div',{staticClass:"window-status"},[_vm._l((_vm.currentVehicle.terminalInfos),function(item,index){return _c('div',{key:index,staticClass:"window-status-item window-status-item0",class:{ 'window-status-item4': item.isOnline }},[_vm._v(_vm._s(_vm.currentVehicle.isCommercialVehicles && item.deviceType == 2 ? 'AEB' : _vm.deviceTypeName[item.deviceType])+"-"+_vm._s(item.isOnline ? '在线' : '离线'))])}),_c('div',{staticClass:"window-status-item",class:[ 'window-status-item' + (_vm.currentVehicle.riskLevel.indexOf('低') > -1 ? '1' : (_vm.currentVehicle.riskLevel.indexOf('中') > -1 ? '2' : '3')) ]},[_vm._v(_vm._s(_vm.currentVehicle.riskLevel))])],2),_c('div',{staticClass:"window-car"},[_c('Iconfont',{attrs:{"size":56,"name":`https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
													_vm.carImgList.includes(_vm.currentVehicle.machineryProductType + _vm.currentVehicle.machineryEquipmentType)
														? _vm.currentVehicle.machineryProductType + _vm.currentVehicle.machineryEquipmentType
														: _vm.carImgList.includes(_vm.currentVehicle.machineryProductType)
														? _vm.currentVehicle.machineryProductType
														: 'excavating_machinery'
											  }.png`}}),_c('div',{staticClass:"window-car-centent"},[_c('div',{staticClass:"window-car-centent-name"},[_vm._v(_vm._s(_vm.currentVehicle.plate)+" "),_c('div',{staticClass:"window-car-centent-name-state window-car-centent-name-state2",class:[ 'window-car-centent-name-state' + _vm.currentVehicle.state ]},[_vm._v(_vm._s(_vm.currentVehicle.state == 1 ? '开工' : _vm.currentVehicle.state == 5 ? '离线' : '停工'))])]),_c('div',{staticClass:"window-car-centent-type"},[_vm._v(_vm._s(_vm.currentVehicle.machineryProductType)+" "+_vm._s(_vm.currentVehicle.frameNo))]),_c('div',{staticClass:"window-car-centent-g"},[_vm._v("车组："+_vm._s(_vm.currentVehicle.groupName))])])],1),_c('div',{staticClass:"window-go"},[_vm._v("车辆详情")]),_c('div',{staticClass:"window-closed",on:{"click":function($event){return _vm.$emit('closed')}}},[_vm._v("X")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }