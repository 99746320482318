<template>
  <div class="ssbj">
    <div class="ssbj-item" v-for="item,index in dataList" :key="index" @click="handleCheke(item)" :id="'alarmItem' + item.vehicleId" :class="{ 'ssbj-item-c': currentVehicle && currentVehicle.vehicleId == item.vehicleId }">
      <div class="ssbj-item-type">
        <div class="ssbj-item-type-name">{{ item.alarmType || '--' }}（{{ item.count || '--' }}）</div>
        <div class="ssbj-item-type-status"  :class="['ssbj-item-type-status' + item.level]">{{ item.level == 1 ? '低' : item.level == 2 ? '中' : '高' }}</div>
      </div>
      <div class="ssbj-item-cell">
        <img src="../../../../assets/images/OperationBigScreen/DeploymentControl/gjsj-icon.png" alt="" srcset="">
        <div class="ssbj-item-cell-label">告警时间：</div>
        <div class="ssbj-item-cell-value text-ellipsis">{{ item.alarmTime }}</div>
      </div>
      <div class="ssbj-item-cell">
        <img src="../../../../assets/images/OperationBigScreen/DeploymentControl/gjdz-icon.png" alt="" srcset="">
        <div class="ssbj-item-cell-label">告警位置：</div>
        <div class="ssbj-item-cell-value text-ellipsis">{{ item.address }}</div>
      </div>
      <div class="ssbj-item-f">
        <div class="ssbj-item-f-item" style="width: 60%">
          <div class="ssbj-item-f-item-label">告警车辆：</div>
          <div class="ssbj-item-f-item-value">{{ item.plate }}</div>
        </div>
        <div class="ssbj-item-f-item">
          <div class="ssbj-item-f-item-label">车身速度：</div>
          <div class="ssbj-item-f-item-value">{{ item.speed }}km/h</div>
        </div>
        <div class="ssbj-item-f-item">
          <div class="ssbj-item-f-item-label">所属车组：</div>
          <div class="ssbj-item-f-item-value">{{ item.groupName }}</div>
        </div>
      </div>
      <div class="ssbj-item-button">
        <div class="ssbj-item-button-left">管控</div>
        <div class="ssbj-item-button-right">查看详情</div>
      </div>
    </div>
    <empty v-if="!dataList || !dataList.length" style="margin-top: 30vh" text="暂无告警数据" />
  </div>
</template>

<script>
import empty from "@/views/OperationBigScreen/DeploymentControl/components/empty";
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    currentVehicle: {
      type: Object,
      default: ()=> {}
    }
  },
  components: { empty },
  watch: {
    currentVehicle: {
      handler(v) {
        if (v && v.id) {
          this.scrollToAnchor('alarmItem' + v.id)
        }
      },
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleCheke(e){
      this.$emit('changeAlarm', e)
    },
    scrollToAnchor(anchor) {
      this.$nextTick(() => {
        const element = document.getElementById(anchor);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.ssbj {
  flex: 1;
  overflow: auto;
  &-item {
    width: 100%;
    // height: 1.97rem;
    padding: 0.3rem 4% 0.2rem;
    background-image: url('../../../../assets/images/OperationBigScreen/DeploymentControl/ssbj-icon.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    &-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.16rem;
      &-name {
        font-weight: 500;
        font-size: 0.18rem;
        color: #E72722;
      }
      &-status {
        height: 0.2rem;
        line-height: 0.2rem;
        font-weight: 500;
        font-size: 0.15rem;
        color: #fff;
        padding: 0 0.14rem;
        border-radius: 0.1rem;
      }
      &-status0 {
        background: #585858;
      }
      &-status1 {
        background: #F9823A;
      }
      &-status2 {
        background: #2E98FF;
      }
      &-status3 {
        background: #E72722;
      }
    }
    &-cell {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 0.16rem;
      margin-bottom: 0.1rem;
      img {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 0.08rem;
      }
      &-label {
        color: #A4A6AD;
      }
      &-value {
        flex: 1;
        color: #fff;
      }
    }
    &-f {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &-item {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 0.16rem;
        margin-bottom: 0.09rem;
        &-label {
          color: #A4A6AD;
        }
        &-value {
          flex: 1;
          color: #fff;
        }
      }
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight: 400;
      font-size: 0.16rem;
      margin-top: 0.18rem;
      &-left {
        color: #98C8FF;
        cursor: pointer;
      }
      &-right {
        color: #1279F1;
        cursor: pointer;
      }
    }
  }
  &-item-c {
    background-image: url('../../../../assets/images/OperationBigScreen/DeploymentControl/ssbj-icon-c.png');
  }
}
</style>