<template>
  <div class="xmxx">
    <div class="xmxx-item" v-for="item,index in boxList" :key="index" :style="{ backgroundImage: `url(${ item.icon })` }">
      <div class="xmxx-item-value"><countTo :startVal='0' :ref="item.valueKey" :autoplay="false" :endVal='Number(options[item.valueKey] || 0)' separator="" :decimals="item.valueKey == 'orderRate' ? 3 : item.units == '台' ? 0 : 2" :duration='2500'></countTo><span v-if="item.valueKey == 'orderRate'">%</span>
      </div>
      <div class="xmxx-item-title">{{ item.title }} <span v-if="item.units">({{ item.units }})</span></div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  props: {
    options: {
      type: Object,
      default: ()=> {},
    }
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(()=> {
          this.boxList.forEach((e)=> {
            this.$refs[e.valueKey][0].start();
          });
        })
      },
    }
  },
  components: { countTo },
  data() {
    return {
      boxList: [
        {
          icon: require('../../../../assets/images/OperationBigScreen/DeploymentControl/gksb-icon.png'),
          title: '管控设备',
          valueKey: 'vehicleCount',
          units: '台'
        },
        {
          icon: require('../../../../assets/images/OperationBigScreen/DeploymentControl/zxsb-icon.png'),
          title: '在线设备',
          valueKey: 'onlineCount',
          units: '台'
        },
        {
          icon: require('../../../../assets/images/OperationBigScreen/DeploymentControl/qdbf-icon.png'),
          title: '签单保费',
          valueKey: 'orderAmount',
          units: '元'
        },
        {
          icon: require('../../../../assets/images/OperationBigScreen/DeploymentControl/qmpfl-icon.png'),
          title: '满期赔付率',
          valueKey: 'orderRate',
          units: ''
        },
      ],
      dataObj: {
        aa: '48003',
        bb: '48003',
        cc: '48003',
        dd: '30',
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.xmxx {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-content: space-evenly;
  flex-wrap: wrap;
  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    height: 40%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: 23%;
    &-value {
      font-weight: bold;
      font-size: 0.28rem;
    }
    &-title {
      font-weight: bold;
      font-size: 0.18rem;
      span {
        font-size: 0.14rem;
      }
    }
    
    @supports (-webkit-background-clip: text) {
      .xmxx-item-value {
        background-image: -webkit-linear-gradient(rgba(243, 247, 254, 1), rgba(120, 203, 255, 1));
        background-image: linear-gradient(rgba(243, 247, 254, 1), rgba(120, 203, 255, 1));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
      }
      .xmxx-item-title {
        background-image: -webkit-linear-gradient(rgba(243, 247, 254, 1), rgba(120, 203, 255, 1));
        background-image: linear-gradient(rgba(243, 247, 254, 1), rgba(120, 203, 255, 1));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
      }
    }
  }
}
</style>