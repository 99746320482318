<template>
  <div class="aqzs">
    <div class="aqzs-left">
      <Chart
        style="height: 100%;"
        ref="chartBox" :notMerge="false"
        :option="pieOptions"
      ></Chart>
      <div class="aqzs-left-zj">
        <img src="../../../../assets/images/OperationBigScreen/DeploymentControl/pie-zj.png" style="width:100%" alt="" srcset="">
        <div class="aqzs-left-zj-v">
          <div class="aqzs-left-zj-v-t">{{ options.score }}<span>%</span></div>
          <div class="aqzs-left-zj-v-z"></div>
        </div>
      </div>
    </div>
    <div class="aqzs-right">
      <div class="aqzs-right-label aqzs-right-label-1">{{ options.appraising }}</div>
      {{ options.remark }}
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  components: { Chart },
  data() {
    return {
      pieOptions: {},
    };
  },
  created() {
  },
  watch: {
    options: {
      handler(v) {
        this.init();
      },
    },
  },
  methods: {
    init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
      this.$nextTick(() => {
        // this.options.oilAndTimeTrend
        this.initChats([
          {
            day: "7/30",
            todayTotalTime: 1,
            todayTotalOil: 2,
          },
          {
            day: "7/29",
            todayTotalTime: 2,
            todayTotalOil: 3,
          },
        ]);
      });
      // })
    },
    initChats(dataList) {
      this.pieOptions = {
         animation: true, // 开启动画效果
        series: [{
            name: '内',
            type: 'pie',
            radius: ['88%', '60%'],
            center: ['50%', '50%'],
            color: [
              '#1fdba5',
              '#327ddc',
            ], 
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            data: [{
                name: "未处理",
                value: this.options.score
              },
              {
                name: "已处理",
                value: 100 - this.options.score
              },
            ]
          },
          {
            name: '外边框',
            type: 'pie',
            clockWise: false, //顺时加载
            hoverAnimation: false, //鼠标移入变大
            center: ['50%', '50%'],
            radius: ['98%', '88%'],
            label: {
              normal: {
                show: false
              }
            },
            data: [{
              value: 1,
              name: '',
              itemStyle: {
                normal: {
                  color: "rgba(0, 173, 255, .1)",
                  // shadowBlur: 10,
                  // shadowColor: 'rgba(22, 55, 51, .3)',
                }
              }
            }]
          }
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.aqzs {
  display: flex;
  height: 100%;
  padding: 0 3%;
  // width: 40%;
  &-left {
    position: relative;
    height: 100%;
    width: 45%;
    &-zj {
      position: absolute;
      width: 53%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      &-v {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        &-t {
          display: flex;
          align-items: flex-end;
          font-weight: bold;
          font-size: 0.28rem;
          color: #E7FFFF;
          span {
            font-size: 0.2rem;
          }
        }
        &-z{
          width: 0.3rem;
          height: 0.04rem;
          margin: auto;
          margin-top: 0.1rem;
          background: #21B45F;
          border-radius: 0.02rem;
        }
      }
    }
  }
  &-right {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    color: #FFFFFF;
    margin-left: 0.12rem;
    &-label {
      position: absolute;
      left: 0;
      top: 10%;
      transform: translateX(-100%);
      height: 0.22rem;
      line-height: 0.22rem;
      padding: 0 0.06rem;
      font-size: 0.14rem;
      color: #FFFFFF;
      border-radius: 0.05rem;
    }
    &-label-1{
      background: #12CC77;
    }
  }
}
</style>