<template>
  <div class="bjxx" v-if="bjxxList && bjxxList.length">
    <div class="bjxx-title">
      <div class="bjxx-title-name">
        {{ bjxxList[currentIndex].title }}
        <img src="../../../../assets/images/OperationBigScreen/DeploymentControl/bjxx-left-icon.png" @click="clickIndex(-1)" alt="" srcset="">
        <img src="../../../../assets/images/OperationBigScreen/DeploymentControl/bjxx-right-icon.png" @click="clickIndex(1)" style="margin-left: 0.06rem" alt="" srcset="">
      </div>
      <div class="bjxx-title-indication">
        <div v-for="item,index in bjxxList" :key="index" :class="{'bjxx-title-indication-c': index == currentIndex}"></div>
      </div>
    </div>
    <!-- <div class="bjxx-content" v-infinite-scroll="load" @mouseenter="handleInterval(true)" @mouseleave="handleInterval()"> -->
      <!-- <div style="height: 100%;max-height: 100%;overflow: hidden;"> -->
        <component :is="bjxxList[currentIndex].componentsKey" :dataList="tableListData[bjxxList[currentIndex].dataType]" :currentVehicle="currentVehicle" @changeAlarm="handleCheke" />
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import ssbj from "@/views/OperationBigScreen/DeploymentControl/components/ssbj"
import bjph from "@/views/OperationBigScreen/DeploymentControl/components/bjph"
export default {
  components: {
    ssbj, bjph
  },
  props: {
    tableListData: {
      type: Object,
      default: ()=> {}
    },
    currentVehicle: {
      type: Object,
      default: ()=> {}
    },
    tableData: {
      type: Array,
      default: ()=> []
    },
  },
  watch: {
    tableListData: {
      handler(v) {
        // if (v && v.length) {
        //   v.length >= 10 ? this.countNum = 10 : this.countNum = v.length
        // }else {
        //   this.countNum = 0
        // }
      }
    },
    tableData: {
      handler(v) {
        if (v && v.length){
          this.bjxxList = v.map(val=> {
            return {
              ...val,
              ...this.bjxxModel[val.dataType]
            }
          });
          this.handleInterval();
        }else {
          this.bjxxList = [];
          this.handleInterval(true);
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      bjxxList: [],
      bjxxModel: {
        4: {
          title: '实时报警',
          componentsKey: 'ssbj',
        },
        5:{
          title: '超速报警排名',
          componentsKey: 'bjph',
        },
        6:{
          title: 'AEB制动报警排名',
          componentsKey: 'bjph',
        },
        7:{
          title: '前向碰撞预警排名',
          componentsKey: 'bjph',
        },
        8:{
          title: '车距检测预警排名',
          componentsKey: 'bjph',
        },
        9:{
          title: '车道偏离预警排名',
          componentsKey: 'bjph',
        },
      },
      currentIndex: 0,
      countNum: 10,
      timeObj: null,
    }
  },
  created() {
  },
  methods: {
    handleCheke(e){
      this.$emit('changeAlarm', e)
    },
    load() {
      if (this.vehicleList && this.vehicleList.length) {
        if (this.countNum + 10 < this.vehicleList.length) {
          this.countNum += 10;
        }else {
          this.countNum += this.vehicleList.length - this.countNum;
        }
      }else {
        this.countNum = 0
      }
    },
    handleInterval(e) {
      if (e || !this.bjxxList[this.currentIndex] || !this.bjxxList[this.currentIndex].duration) {
        clearTimeout(this.timeObj);
        this.timeObj = null;
      }else {
        clearTimeout(this.timeObj);
        this.timeObj = setTimeout(()=>{
          this.changeIndex();
          this.handleInterval();
        }, Number(this.bjxxList[this.currentIndex].duration) * 1000)
      }
    },
    changeIndex() {
      this.currentIndex == this.bjxxList.length - 1 ? this.currentIndex = 0 : this.currentIndex += 1;
    },
    clickIndex(num) {
      if (num > 0) {
        this.currentIndex == this.bjxxList.length - 1 ? this.currentIndex = 0 : this.currentIndex += 1;
      }else {
        this.currentIndex == 0 ? this.currentIndex = this.bjxxList.length - 1 : this.currentIndex -= 1;
      };
      this.handleInterval();
    },
  }
}
</script>

<style lang="scss" scoped>
.bjxx {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.16rem 0 ;
    &-name{
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 0.21rem;
      color: #7EC0FF;
      img {
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.1rem;
        cursor: pointer;
      }
    }
    &-indication {
      display: flex;
      align-items: center;
      gap: 0.08rem;
      div {
        width: 0.08rem;
        height: 0.08rem;
        background: rgba(82, 204, 255, .4);
        border-radius: 50%;
      }
      &-c {
        background: #52CCFF !important;
      }
    }
  }
  &-content {
    flex: 1;
    overflow: auto;
  }
}
</style>