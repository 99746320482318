<template>
  <div ref="BigScreenDisplay" class="data-center">
    <!-- 布局头部 -->
    <div class="header bg1">
      <div class="path-left">
        <img
          src="../../../assets/images/bigScreen/equipment/goHome.png"
          @click="handleGoPath('/SCProjectHall')"
          style="width: 0.3rem"
          class="go-home"
          alt=""
          srcset=""
        />
        <img
          @click="handleGoPath()"
          src="@/assets/images/OperationBigScreen/DeploymentControl/back.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          alt=""
          srcset=""
        />
      </div>
      <!-- <div class="header_text_weather">
        <i class="el-icon-location"></i>
        <span style="margin-left: 0.5em">{{ weatherArea }}</span>
        <img class="header_weather" :src="weatherImg" alt="" />
        <span style="margin-left: 0.1em">{{ weatherText }}</span>
      </div> -->
      <span style="flex: 1"></span>
      <div class="header-title p-clip">{{ projectObj.lsProjectName }}监控</div>
      <span class="time-pane">
        {{ dateText }}
        <span style="margin: 0 0.5em">{{ dayNum }}</span>
        {{ timeStr }}
      </span>

      <div class="path-right">
        <img
          @click="SetFullscreen"
          src="@/assets/images/OperationBigScreen/DeploymentControl/enlarge.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <!-- 布局中间部分 -->
    <div class="MainOuter">
      <img
        src="@/assets/images/OperationBigScreen/MapEvents/top-decoration.png"
        class="top-decoration"
        alt=""
        srcset=""
      />
      <BSJMap
        ref="mapRef"
        :zoom="zoom"
        :center="centerLng"
        mapStyle="amap://styles/30b04e25b1e43697d8e46ca5eb19f169"
        @loaded="onMapLoad"
      >
        <!-- 聚合点 -->
        <MapClusterer
          ref="clusterObj"
          :points="clusterPoints"
          :gridSize="2"
          pointKey="key"
          :markerOffset="markerOffset"
          @markerClick="onMarkerClick"
        >
          <template #marker="{ point }">
            <!-- <Iconfont
              :name="getProductTypeIconName(point.data.machineryProductType, point.data.machineryEquipmentType)"
              :size="32"
              :rotate="point.data.F || 0"
            ></Iconfont>-->
            <div
              v-if="currentVehicle && currentVehicle.vehicleId == point.data.vehicleId"
              style="margin-top: -50px; margin-left: -6px"
            >
              <Iconfont
                :size="51"
                :height="81"
                :name="vehicleIconName(point.data, true)"
              ></Iconfont>
            </div>
            <Iconfont
              v-else
              :size="40"
              :height="40"
              :name="vehicleIconName(point.data)"
            ></Iconfont>
          </template>
        </MapClusterer>

        <!-- 展示地图信息窗体 -->
        <InfoWindow
          ref="windowObj"
          v-model="infoVisible"
          :lngLat="windowPoint"
          :offset="[-4, -60]"
          :width="424"
          :height="0"
          autoMove
        >
          <Window
            :currentVehicle="currentVehicle"
            @closed="(infoVisible = false), (currentVehicle = null)"
          />
        </InfoWindow>
      </BSJMap>
      <div class="arrangement">
        <div class="arrangement-left" v-loading="leftLoading">
          <div :class="[ item.dataType == 1 ? 'xmxx-box' : item.dataType == 2 ? 'bjsqst-box' : 'aqzs-box' ]" v-for="item,index in contentList.leftContent" :key="index">
            <xmxx v-if="item.dataType == 1" :options="leftOption"  />
            <bjsqst v-else-if="item.dataType == 2" :options="leftOption" />
            <aqzs v-else-if="item.dataType == 3" :options="leftOption.safetyIndex || {}" />
          </div>
          <!-- <div class="bjsqst-box">
            <bjsqst />
          </div>
          <div class="aqzs-box">
            <aqzs />
          </div> -->
        </div>
        <div class="arrangement-content" v-loading="cententLoading">
          <div class="arrangement-content-top">
            <div class="arrangement-content-top-item">布控地图</div>
            <div
              class="arrangement-content-top-item arrangement-content-top-item1"
              @click="handleGoPath('/SCMapEvents')"
            >
              事件地图
            </div>
            <img
              src="../../../assets/images/OperationBigScreen/DeploymentControl/zs-l.png"
              class="arrangement-content-top-icon"
              alt=""
              srcset=""
            />
          </div>
          <div class="arrangement-content-control">
            <img @click="centerLng = JSON.parse(JSON.stringify(idPositioning))"
              src="../../../assets/images/OperationBigScreen/DeploymentControl/positioning-icon.png"
              alt=""
              srcset=""
            />
            <img
              src="../../../assets/images/OperationBigScreen/DeploymentControl/enlarged-icon.png"
              alt=""
              @click="zoom < 18 ? (zoom += 1) : ''"
              srcset=""
            />
            <img
              src="../../../assets/images/OperationBigScreen/DeploymentControl/narrow-icon.png"
              @click="zoom > 3 ? (zoom -= 1) : ''"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="arrangement-right bjxx-box" v-if="contentList.rightContent && contentList.rightContent.length" v-loading="rightLoading">
          <div style="height: 6%"></div>
          <bjxx
            :tableListData="tableListData" :tableData="contentList.rightContent"
            :currentVehicle="currentVehicle"
            @changeAlarm="
              (e) => {
                (infoVisible = true), (currentVehicle = e);
              }
            "
          />
        </div>
        <div class="arrangement-right" v-else></div>
      </div>
    </div>
  </div>
</template>
<script>
import { weatherInfo, amapGetIp } from "@/api/weather";
import { getWeek, getTimes } from "@/utils/getDate";
import dayjs from "dayjs";
import { mapMutations } from "vuex";
import BSJMap, { InfoWindow, MapClusterer } from '@/components/AMap';
import xmxx from "@/views/OperationBigScreen/DeploymentControl/components/xmxx"
import bjsqst from "@/views/OperationBigScreen/DeploymentControl/components/bjsqst"
import aqzs from "@/views/OperationBigScreen/DeploymentControl/components/aqzs"
import Window from "@/views/OperationBigScreen/DeploymentControl/components/Window"
import bjxx from "@/views/OperationBigScreen/DeploymentControl/components/bjxx"
import GPS from '@/utils/address';
import { projectLSContentList, dataOverview, projectLSBKMap, alarmCarousel } from "@/api/riskData.js";

export default {
  // `name`与路由保持一致
  name: "SCDeploymentControl",
  components: { BSJMap, InfoWindow, MapClusterer, xmxx, bjsqst, aqzs, Window, bjxx },
  data() {
    // this.intervalId = null;
    return {
      timeStr: "", // 时间
      timer: null, // 时间定时器
      loading: false, //加载
      weatherText: "", //天气说明
      weatherImg: "", //天气图标
      weatherArea: "", //天气地址
      vehicleTotal: 0,
      fullscreenchange: null,
      visibilitychange: null,
      backLoading: false,
      intervalId: null,
      dayNum: "",
      dateText: "",
      centerLng: null,
      zoom: 10,
      markerOffset: [0, 0],
      infoVisible: false, //
      currentVehicle: null,
      vehicleList: [],
      projectObj: {},
      contentList: {
        leftContent: [{
          dataType: 1,
          duration: null,
          isDisplayed: true,
          sort: 1
        },{
          dataType: 2,
          duration: null,
          isDisplayed: true,
          sort: 2
        },{
          dataType: 3,
          duration: null,
          isDisplayed: true,
          sort: 3
        }],
        rightContent: [
          {
          dataType: 4,
          duration: 3,
          isDisplayed: true,
          sort: 1
        },{
          dataType: 5,
          duration: 3,
          isDisplayed: true,
          sort: 2
        },{
          dataType: 6,
          duration: 3,
          isDisplayed: true,
          sort: 3
        },{
          dataType: 7,
          duration: 3,
          isDisplayed: true,
          sort: 3
        },{
          dataType: 8,
          duration: 3,
          isDisplayed: true,
          sort: 3
        },{
          dataType: 9,
          duration: 3,
          isDisplayed: true,
          sort: 3
        }
        ]
      },
      tableListData: {
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
      },
      leftOption: {},
      cententLoading: false, //
      leftLoading: false, //
      rightLoading: false, //
      idPositioning: []
    };
  },
  created() {
  },
  // mounted
  activated() {
    let that = this;
    if (this.$route.query.project) {
      this.projectObj = JSON.parse(this.$route.query.project);
    }
    this.init();
    this.handleGetWeather();
    var datas = dayjs().day();
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    this.dayNum = "周" + week[datas];
    this.dateText = dayjs().format("YYYY-MM-DD");
    this.timer = setInterval(() => {
      const today = dayjs(new Date()).format("HH:mm:ss");
      this.timeStr = `${today}`;
    }, 1000);

    // this.intervalId = setInterval(async () => {
    //   await this.init();
    // }, 1000 * 30);
    this.fullscreenchange = true;
    addEventListener("fullscreenchange", this.handleFullscreenchange);
    addEventListener("visibilitychange", this.handleVisibilitychange);
  },
  // destroyed
  deactivated() {
    clearInterval(this.timer); //销毁定时器
    // clearInterval(this.intervalId);
    clearTimeout(this.intervalId);
    this.intervalId = null;
    this.fullscreenchange = null;
    removeEventListener("fullscreenchange", this.handleFullscreenchange);
    removeEventListener("visibilitychange", this.handleVisibilitychange);
  },
  computed: {
    // 聚合点
    clusterPoints() {
      const { vehicleList } = this;
      return vehicleList.map((obj,indd) => {
        const point = GPS.delta(obj.lat, obj.lon);
        if (indd == 0) {
          this.centerLng = [point.lon, point.lat]
        }
        return {
          key: obj.vehicleId || indd,
          lng: point.lon,
          lat: point.lat,

          // lng: 116.40121,
          // lat: 39.907795,

          data: obj
        };
      });
    },
    windowPoint() {
      // this.infoVisible = true;
      // return [116.40121, 39.907795];
      const { currentVehicle } = this;
      if (!currentVehicle) {
        this.infoVisible = false;
        return [];
      }
      const point = GPS.delta(currentVehicle.lat, currentVehicle.lon);

      return [point.lon, point.lat];
    },
  },

  methods: {
    async init() {
      this.getModelData();
      let { data, code } = await projectLSContentList({
        lsProjectId: this.projectObj.id || 1,
        type: '',
      });
      if (code == 200 && data.length) {
        let leftContent = data.filter(vv=> vv.dataType < 4).sort(this.compare('sort'));
        let rightContent = data.filter(vv=> vv.dataType > 3 && vv.dataType < 10).sort(this.compare('sort'));
        this.contentList = {
          leftContent: leftContent.filter(val=> val.isDisplayed),
          rightContent: rightContent.filter(val=> val.isDisplayed),
        };
      } else {
        // this.contentList = [];
      }
    },
    getModelData() {
      this.leftLoading = true;
      dataOverview({
        lsProjectId: this.projectObj.id,
      }).then(res=> {
        this.leftLoading = false;
        this.leftOption = res.data || {};
      }).catch(err=> {
        this.leftOption = {};
        this.leftLoading = false;
        console.log(err);
      });
      this.cententLoading = true;
      projectLSBKMap({
        lsProjectId: this.projectObj.id,
      }).then(res=> {
        this.cententLoading = false;
        this.vehicleList = res.data || [];
        let realTimeAlarmList = [];
        if (res.data && res.data.length) {
          res.data.forEach(val=> {
            if (val.realTimeAlarmList && val.realTimeAlarmList.length) {
              val.realTimeAlarmList.forEach(vv=> {
                realTimeAlarmList.push({
                  ...val,
                  ...vv
                })
              });
            }
          });
          this.tableListData[4] = realTimeAlarmList || [];
        }
        // this.tableListData[4] = res.data || [];
      }).catch(err=> {
        this.vehicleList = [];
        this.cententLoading = false;
        // this.tableListData[4] = [];
        console.log(err);
      });
      this.rightLoading = true;
      alarmCarousel({
        lsProjectId: this.projectObj.id,
      }).then(res=> {
        this.rightLoading = false;
        this.tableListData[5] = res.data.cSAlarmList || [];
        this.tableListData[6] = res.data.aebAlarmList || [];
        this.tableListData[7] = res.data.aebFcwAlarmList || [];
        this.tableListData[8] = res.data.hmwAlarmList || [];
        this.tableListData[9] = res.data.aebLdwAlarmList || [];
      }).catch(err=> {
        this.rightLoading = false;
        this.tableListData[4] = [];
        this.tableListData[5] = [];
        this.tableListData[6] = [];
        this.tableListData[7] = [];
        this.tableListData[8] = [];
        this.tableListData[9] = [];
        console.log(err);
      });
    },
    compare(properct){
      return (a, b)=> {
        let value1 = a[properct];
        let value2 = b[properct];
        return value1 - value2;
      }
    },
    handleFullscreenchange() {
      this.init();
    },
    handleVisibilitychange() {
      if (document.visibilityState == "hidden") {
        clearTimeout(this.intervalId);
        this.intervalId = null;
      } else {
        this.init();
        if (!this.intervalId) {
          this.handlePolling();
        }
      }
    },
    handlePolling() {
      // this.intervalId = setTimeout(async () => {
      //   await this.init();
      //   clearTimeout(this.intervalId);
      //   if (this.fullscreenchange) {
      //     this.handlePolling();
      //   }
      // }, 1000 * 60);
    },
    getFullScreenEl() {
      const { el } = this;
      // return (isElement('el')
      //   ? el
      //   : document.querySelector(el)) || document.documentElement;
      return this.$refs["BigScreenDisplay"];
    },
    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },

    async handleGetWeather() {
      let cityData = await amapGetIp({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
      });
      let imgList = [
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain1.png", //小雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain2.png", //大雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain3.png", // 雷雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/cloud.png", //多云
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/snow.png", //雪
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/clear.png", // 晴
      ];
      let rectangle = cityData.rectangle.split(";");
      let lan = Number(rectangle[0].split(",")[0]) + Number(rectangle[1].split(",")[0]);
      let lon = Number(rectangle[0].split(",")[1]) + Number(rectangle[1].split(",")[1]);
      this.idPositioning = [lan / 2, lon / 2]
      weatherInfo({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
        city: cityData.adcode,
      }).then((res) => {
        let weatherData = res.lives[0];
        // let high = weatherData.high.split(' ')[1];
        // let low = weatherData.low.split(' ')[1];
        this.weatherArea = cityData.city;
        this.weatherText =
          weatherData.temperature + "°" + " " + weatherData.weather;
        if (weatherData.weather.indexOf("雷") > -1) {
          this.weatherImg = imgList[2];
        } else if (weatherData.weather.indexOf("大雨") > -1) {
          this.weatherImg = imgList[1];
        } else if (weatherData.weather.indexOf("雨") > -1) {
          this.weatherImg = imgList[0];
        } else if (weatherData.weather.indexOf("雪") > -1) {
          this.weatherImg = imgList[4];
        } else if (weatherData.weather.indexOf("晴") > -1) {
          this.weatherImg = imgList[5];
        } else if (weatherData.weather.indexOf("云") > -1) {
          this.weatherImg = imgList[3];
        } else {
          this.weatherImg = imgList[3];
        }
      });
    },
    handleGoPath(path) {
      if (!path) {
        this.$router.go(-1);
      }
      this.$router.push({
        path: path + '?project=' + JSON.stringify(this.projectObj),
      });
    },
    onMapLoad(e) {
      console.log(e, '---地图加载完成---');
    },
    onMarkerClick(e) {
      this.infoVisible = true;
      this.currentVehicle = e.data;
      console.log(e, '---点击标点---');
    },
    vehicleIconName(val, current) {
      return require(`../../../assets/images/OperationBigScreen/DeploymentControl/car-icon-${val.isOnline ? val.riskLevelNum : '0'}${current ? '-current' : ''}.png`);
    },
    ...mapMutations(["SetFullscreen"]),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
::v-deep .amap-maptypecontrol {
  display: none;
}
::v-deep .amap-controls {
  display: none;
}
::v-deep .WindowContent {
  background-color: transparent;
  border: none;
}
::v-deep .AnchorArrow {
  display: none;
}
</style>
