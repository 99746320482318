<template>
  <div class="window" v-if="currentVehicle">
    <div class="window-status">
      <div class="window-status-item window-status-item0" :class="{ 'window-status-item4': item.isOnline }" v-for="item,index in currentVehicle.terminalInfos" :key="index">{{ currentVehicle.isCommercialVehicles && item.deviceType == 2 ? 'AEB' : deviceTypeName[item.deviceType]}}-{{ item.isOnline ? '在线' : '离线'}}</div>
      <div class="window-status-item" :class="[ 'window-status-item' + (currentVehicle.riskLevel.indexOf('低') > -1 ? '1' : (currentVehicle.riskLevel.indexOf('中') > -1 ? '2' : '3')) ]">{{ currentVehicle.riskLevel }}</div>
    </div>
    <div class="window-car">
      <!-- <img src="https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/excavating_machinery.png" class="window-car-img" alt="" srcset=""> -->
      
          <Iconfont :size="56" :name="`https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
													carImgList.includes(currentVehicle.machineryProductType + currentVehicle.machineryEquipmentType)
														? currentVehicle.machineryProductType + currentVehicle.machineryEquipmentType
														: carImgList.includes(currentVehicle.machineryProductType)
														? currentVehicle.machineryProductType
														: 'excavating_machinery'
											  }.png`"></Iconfont>
      <div class="window-car-centent">
        <div class="window-car-centent-name">{{ currentVehicle.plate }} <div class="window-car-centent-name-state window-car-centent-name-state2" :class="[ 'window-car-centent-name-state' + currentVehicle.state ]">{{ currentVehicle.state == 1 ? '开工' : currentVehicle.state == 5 ? '离线' : '停工' }}</div> </div>
        <div class="window-car-centent-type">{{ currentVehicle.machineryProductType }} {{ currentVehicle.frameNo }}</div>
        <div class="window-car-centent-g">车组：{{ currentVehicle.groupName }}</div>
      </div>
    </div>
    <div class="window-go">车辆详情</div>
    <div class="window-closed" @click="$emit('closed')">X</div>
  </div>
</template>

<script>
export default {
  props: {
    currentVehicle: {
      type: Object,
      default: ()=> {}
    }
  },
  data() {
    return {
      deviceTypeName: [
        '',
        'OVP',
        'OBD',
        '有线GPS',
        '无线GPS',
        'PFT',
        'BSD',
      ],
				carImgList: [
					'aerial_work_machinery1',
					'aerial_work_machinery2',
					'compaction_machinery',
					'excavating_machinery',
					'excavating_machinery1',
					'hoisting_machinery',
					'hoisting_machinery3',
					'mining_machinery',
					'piling_machinery1',
					'shovel_transport_machinery0',
					'shovel_transport_machinery1',
					'shovel_transport_machinery3',
					'industrial_vehicles1',
					'agriculture_mechanics1',
					'concrete_machinery3',
					'shovel_transport_machinery9',
          'diao_guan_ji0',
          'yi_dong_dian_zhan0',
          'han_jie_gong_zuo_zhan0',
          'zhong_pin_jia_re_che0',
          'new_energy_vehicles0',
				],
    }
  }
}
</script>

<style lang="scss" scoped>
.window {
  position: relative;
  width: 424px;
  height: 171px;
  padding: 30px;
  padding-bottom: 10px;
  background-image: url('../../../../assets/images/OperationBigScreen/DeploymentControl/car-win.png');
  background-size: 100%;
  background-repeat: no-repeat;
  &-closed {
    position: absolute;
    top: 8px;
    right: 18px;
    font-weight: bold;
    font-size: 18px;
    color: #52CCFF;
    cursor: pointer;
  }
  &-status {
    display: flex;
    gap: 8px;
    &-item {
      height: 21px;
      line-height: 21px;
      font-size: 12px;
      color: #fff;
      padding: 0 10px;
      border-radius: 10px;
    }
    &-item0 {
      background: #585858;
    }
    &-item1 {
      background: #F9823A;
    }
    &-item2 {
      background: #2E98FF;
    }
    &-item3 {
      background: #E72722;
    }
    &-item4 {
      background: #27B879;
    }
  }
  &-car {
    margin-top: 10px;
    display: flex;
    img {
      width: 56px;
      height: 56px;
      margin-right: 13px;
      margin-top: 0;
    }
    &-centent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-name {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        color: #B2D9FF;
        &-state {
          height: 22px;
          line-height: 22px;
          font-weight: 400;
          font-size: 12px;
          margin-left: 12px;
          padding: 0 10px
        };
        &-state1 {
          background: rgba(39,184,121,0.2) !important;
          border-radius: 10px;
          border: 1px solid #27B879 !important;
          color: #27B879 !important;
        };
        &-state2 {
          background: rgba(88, 88, 88, 1);
          border-radius: 10px;
          border: 1px solid #fff;
          color: #fff;
        };
      }
      &-type {
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
      &-g {
        font-size: 14px;
        color: #FFFFFF;
      }
    }
  }
  &-go {
    margin: auto;
    font-size: 14px;
    color: #52CCFF;
    text-align: center;
    cursor: pointer;
    margin-top: 18px;
  }
}
</style>