<template>
  <div class="screen-user">
    <Chart
      style="height: 100%; min-height: 100px"
      ref="chartBox"
      :option="lineOptions"
    ></Chart>
    <!-- <div class="quantity-box">
         <div :class="{ 'quantity-box-bg': item != ',' && item != '.' }" v-for="item,index in quantityNum" :key="index">
            <span>{{ item }}</span>
         </div>
      </div> -->
    <!-- <div class="line-label">
         <div class="line-label-view" v-for="item,index in typeList" :key="index">
            <div class="line-label-view-circle" :class="['line-label-view-circle'+index]"></div>
            <div class="line-label-view-text">{{ item }}</div>
         </div>
      </div> -->
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
// import { getOilAndTimeTrend } from "@/api/BigScreenDisplay";
export default {
  components: { Chart },
  data() {
    return {
      lineOptions: {},
      typeList: ["工时", "油耗"],
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  created() {
  },
  watch: {
    options: {
      handler(v) {
        this.init();
      },
    },
  },
  methods: {
    init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
      let overviewAlarmDay = [];
      let seriesListInd = {};
      let seriesList = [];
      for (const key in this.options.overviewAlarmList) {
        overviewAlarmDay.push(key);
        this.options.overviewAlarmList[key].map(val=> {
          if (seriesListInd[val.alarmTypeId] || seriesListInd[val.alarmTypeId] === 0) {
            seriesList[seriesListInd[val.alarmTypeId]].data.push(val.alarmCount);
          }else {
            seriesListInd[val.alarmTypeId] = seriesList.length;
            seriesList.push({
              data: [val.alarmCount],
              name: val.alarmType
            })
          }
        })
      };
      this.$nextTick(() => {
        this.initChats(seriesList, overviewAlarmDay);
      });
      // })
    },
    initChats(dataList, xAxisData) {
      // let xAxisData = xData;
      let colorList = ['rgba(0, 251, 207, 1)',"rgba(40, 189, 244, 1)","rgba(255, 244, 6, 1)","rgba(232, 138, 234, 1)","rgba(255, 104, 3, 1)"];
      const series = dataList.map((val,indd)=> {
        return {
          ...val,
          type: "line",
          smooth: true,
          showSymbol: false,
          symbol: "circle",
          itemStyle: {
            color: colorList[indd] || colorList[0],
            borderWidth: 1,
          },
        }
      })
      // 数图配置项
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          //  formatter: function (params, ticket, callback) {
          //    // console.log(params)
          //    let str = `${ params[0].axisValue }</br>`;
          //    params.forEach((item, idx) => {
          //      str += idx === params.length - 1 ? "" : "<br/>";
          //    });
          //    return str;
          //  }
        },
        grid: {
          top: "34%", // 等价于 y: '16%'
          left: "8%",
          right: "14%",
          bottom: "0%",
          containLabel: true,
        },
        legend: {
          itemGap: 10,
          itemWidth: 10,
          data: series.map((val) => val.name),
          icon: "circle",
          textStyle: {
            //图例文字的样式
            color: "#fff",
          },
          left: "center",
          top: 10,
        },
        xAxis: {
          type: "category",
          name: "日期",
          boundaryGap: false,
          data: xAxisData,
          axisLine: {
            lineStyle: {
              color: "#575E71",
            },
          },
          nameTextStyle: {
            color: "#fff",
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },

        yAxis: {
          type: "value",
          name: "报警数量",
          position: "left",
          axisLine: {
            lineStyle: {
              color: "#575E71",
            },
          },
          nameTextStyle: {
            color: "#fff",
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
            },
          },
          axisLabel: {
            formatter: function (value) {
              return value;
            },
            textStyle: {
              color: "#fff",
            },
          },
        },
        series: series,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.screen-user {
  position: relative;
  height: 89%;
  .quantity-box {
    position: absolute;
    top: -9.5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    div {
      span {
        font-size: 0.48rem;
        font-family: "ZhanKuQingKeHuangYouTi";
        background-image: -webkit-linear-gradient(
          bottom,
          rgba(22, 142, 245, 1),
          rgba(255, 255, 255, 1)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-bg {
      padding: 0 0.16rem;
      background-image: url("../../../../assets/images/bigScreen/home/quantity.png");
      background-size: 100% 100%;
    }
  }

  .line-label {
    position: absolute;
    right: 20px;
    top: 0;
    display: flex;
    justify-content: space-around;
    &-view {
      display: flex;
      align-items: center;
      margin-left: 10px;
      &-circle {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 8px;
        border-radius: 50%;
        background-color: rgba(150, 245, 248, 1);
      }
      &-text {
        font-size: 0.16rem;
        color: #ffffff;
      }
      &-circle1 {
        background-color: rgba(255, 253, 55, 1);
      }
    }
  }
}
</style>