import dayjs from "dayjs";

function getRencentlySevenDay(tarDay) {
   //date为中国标准时间:例 new Date()结果的格式
   var tarDay1 = dateFormat(
      new Date(tarDay.getTime() - 0 * 24 * 60 * 60 * 1000)
   );
   var tarDay2 = dateFormat(
      new Date(tarDay.getTime() + 1 * 24 * 60 * 60 * 1000)
   );
   var tarDay3 = dateFormat(
      new Date(tarDay.getTime() + 2 * 24 * 60 * 60 * 1000)
   );
   var tarDay4 = dateFormat(
      new Date(tarDay.getTime() + 3 * 24 * 60 * 60 * 1000)
   );
   var tarDay5 = dateFormat(
      new Date(tarDay.getTime() + 4 * 24 * 60 * 60 * 1000)
   );
   var tarDay6 = dateFormat(
      new Date(tarDay.getTime() + 5 * 24 * 60 * 60 * 1000)
   );
   var tarDay7 = dateFormat(
      new Date(tarDay.getTime() + 6 * 24 * 60 * 60 * 1000)
   );
   let targetDay =
      tarDay1 +
      "," +
      tarDay2 +
      "," +
      tarDay3 +
      "," +
      tarDay4 +
      "," +
      tarDay5 +
      "," +
      tarDay6 +
      "," +
      tarDay7;
   // this.chartData.data = targetDay.split(",");
}
function dateFormat(date) {
   //date为中国标准时间:例 new Date()结果的格式
   var year = date.getFullYear().toString();
   var month = date.getMonth() + 1;
   if (month < 10) {
      month = "0" + month.toString();
   } else {
      month = month.toString();
   }
   var day = date.getDate();
   if (day < 10) {
      day = "0" + day.toString();
   } else {
      day = day.toString();
   }
   return month + "-" + day; //返回格式为20190615
}
function getNextDay(d) {
   d = new Date(d);
   d = +d + 1000 * 60 * 60 * 24;
   d = new Date(d);
   //格式化
   return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
}

//获取周
export function getWeek(dateStrs) {
   //获取当前周
   let curD = new Date();
   let firstD = new Date();
   let lastD = new Date();
   let intD = new Date();
   let dayTime = 24 * 60 * 60 * 1000;
   var dateLong = curD.getTime();
   if (curD.getDay() == 0) {
      //当前时间取代过去的时间
      dateLong = dateLong - 6 * dayTime;
   } else {
      dateLong = dateLong - (curD.getDay() - 1) * dayTime;
   }
   let d = curD.setTime(dateLong);
   let tt = Date.parse(firstD);
   firstD = curD;
   lastD.setTime(firstD.getTime() + 6 * dayTime);
   let firstM = firstD.getMonth() + 1;
   firstM = firstM < 10 ? "0" + firstM : firstM;
   let startD = firstD.getDate();
   startD = startD < 10 ? "0" + startD : startD;
   let st = firstD.getFullYear() + "-" + firstM + "-" + startD;

   let endM = lastD.getMonth() + 1;
   endM = endM < 10 ? "0" + endM : endM;

   let endD = lastD.getDate();
   endD = endD < 10 ? "0" + endD : endD;
   let et = lastD.getFullYear() + "-" + endM + "-" + endD;
   // this.queryOil.dateStr = `${ st }`; 
   // let nextEt = getNextDay(et);
   // 周开始
   var startTime = dayjs(st).format("YYYY-MM-DD 00:00:00");
   //获取周结束后一天
   var endTime = dayjs(et).format("YYYY-MM-DD 23:59:59");
   let ss = st + " ~ " + et;
   getRencentlySevenDay(new Date(st));

   // return st;
   return [st, startTime, endTime];
}


export function getNextDays(d) {
   var d = new Date(d);
   d = +d + 1000 * 60 * 60 * 24;
   d = new Date(d);
   //格式化
   const dates = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
   const endTime = dayjs(dates).format("YYYY-MM-DD");
   return endTime;
}

export function getTimes(val) {
   let startTime, endTime;
   let month = dayjs().month() + 1;
   month = month < 10 ? "0" + month : month;
   const year = dayjs().year();

   if (val == 1) {
      startTime = getWeek()[1];
      endTime = getWeek()[2];
   } else if (val == 2) {
      const dateStr = `${ year }-${ month }-01`;
      startTime = dayjs(dateStr).format("YYYY-MM-DD 00:00:00");
      endTime = dayjs(`${ year }-${ month }`).endOf('month').format('YYYY-MM-DD 23:59:59');
   } else if (val == 3) {
      const dateStr = `${ year }-01-01`;
      startTime = dayjs(dateStr).format("YYYY-MM-DD 00:00:00");
      endTime = dayjs(`${ year }-12-31`).format("YYYY-MM-DD 23:59:59");
   }
   return [startTime, endTime];

}

export function getOilTimes(val) {
   let dateStr;
   let month = dayjs().month() + 1;
   month = month < 10 ? "0" + month : month;
   const year = dayjs().year();
   if (val == "W") {
      dateStr = getWeek()[0];
   } else if (val == "M") {
      dateStr = `${ year }-${ month }-01`;
   } else {
      dateStr = `${ year }-01-01`;
   }
   return dateStr;
}

